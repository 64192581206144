// PoxBase
// Copyright (C) 2020  Maciej Hirsz
//
// This program is free software: you can redistribute it and/or modify
// it under the terms of the GNU General Public License as published by
// the Free Software Foundation, either version 3 of the License, or
// (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program.  If not, see <http://www.gnu.org/licenses/>.

import React from 'react';

interface Props {
  kind: string;
  className?: string;
}

export class Icon extends React.Component<Props> {
  shouldComponentUpdate(nextProps: Props) {
    return (
      this.props.kind !== nextProps.kind ||
      this.props.className !== nextProps.className
    )
  }

  render() {
    const className = this.props.className
      ? `icon-${this.props.kind} ${this.props.className}`
      : `icon-${this.props.kind}`;

    return <i className={className} />;
  }
}
